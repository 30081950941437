.overview {
  &-binoculars {
    color: #ffffff;
    width: 20px;
    height: 20px;

    & path {
      stroke: currentColor;
    }

    &.warning {
      color: rgb(255, 251, 0);
      animation: swing 1s ease-in-out infinite;
      transform-origin: center;
    }

    @media (min-width: 768px) {
      width: 22px;
      height: 22px;
    }
  }

  &-point {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation: backHomePulsePoint 1s infinite;
  }

  &-warning {
    display: none;
    opacity: 0;
    position: absolute;
    padding: 6px 10px;
    top: 55px;
    background-color: rgba(0, 0, 0, 0.85);
    color: rgb(202, 191, 191);
    border-radius: 6px;
    transition: 3s;
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid rgba(0, 0, 0, 0.85);
    }

    &.show {
      opacity: 1;
    }

    @media (min-width: 768px) {
      display: block;
    }

    @media (min-width: 1441px) {
      top: 65px;
    }
  }

  @keyframes backHomePulsePoint {
    0% {
      box-shadow: 0 0 0 0 rgb(255, 251, 0);
    }

    70% {
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  @keyframes swing {
    0% {
      transform: rotate(0) scale(1) skew(1deg);
    }
    10% {
      transform: rotate(-25deg) scale(1) skew(1deg);
    }
    20% {
      transform: rotate(25deg) scale(1) skew(1deg);
    }
    30% {
      transform: rotate(-25deg) scale(1) skew(1deg);
    }
    40% {
      transform: rotate(25deg) scale(1) skew(1deg);
    }
    50% {
      transform: rotate(0) scale(1) skew(1deg);
    }
    100% {
      transform: rotate(0) scale(1) skew(1deg);
    }
  }
}
